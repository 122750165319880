import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='app-footer'>
        <h2>2022 &copy; Producto Petrificus</h2>
    </div>
  )
}

export default Footer
